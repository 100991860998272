import { v4 as uuidv4 } from "uuid";

import mobileDesign1 from "../../img/project/mobile-design-1.png";
import mobileDesign2 from "../../img/project/mobile-design-2.png";
import mobileDesign3 from "../../img/project/mobile-design-3.png";
import mobileDesign4 from "../../img/project/mobile-design-4.png";

import webDesign1 from "../../img/project/web-design-1.png";
import webDesign2 from "../../img/project/web-design-2.png";
import webDesign3 from "../../img/project/web-design-3.png";
import webDesign4 from "../../img/project/web-design-4.png";

import branding1 from "../../img/project/branding-1.png";
import branding2 from "../../img/project/branding-2.png";
import branding3 from "../../img/project/branding-3.png";
import branding4 from "../../img/project/branding-4.png";

import illustration1 from "../../img/project/illustration-1.png";
import illustration2 from "../../img/project/illustration-2.png";
import illustration3 from "../../img/project/illustration-3.png";
import illustration4 from "../../img/project/illustration-4.png";

export const cardsLayout = [
  {
    id: uuidv4(),
    filter: ["website design"],
    img: webDesign4,
    title: "Website Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["illustration"],
    img: illustration1,
    title: "Illustration",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["illustration"],
    img: illustration3,
    title: "Illustration",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["illustration"],
    img: illustration2,
    title: "Illustration",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["illustration"],
    img: illustration4,
    title: "Illustration",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },
  {
    id: uuidv4(),
    filter: ["mobile apps"],
    img: mobileDesign1,
    title: "Mobile Apps Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["mobile apps"],
    img: mobileDesign2,
    title: "Mobile Apps Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["mobile apps"],
    img: mobileDesign3,
    title: "Mobile Apps Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["mobile apps"],
    img: mobileDesign4,
    title: "Mobile Apps Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["mobile apps"],
    img: mobileDesign1,
    title: "Mobile Apps Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  // Website desing
  {
    id: uuidv4(),
    filter: ["website design"],
    img: webDesign1,
    title: "Website Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["website design"],
    img: webDesign2,
    title: "Website Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["website design"],
    img: webDesign3,
    title: "Website Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["website design"],
    img: webDesign1,
    title: "Website Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["website design"],
    img: webDesign2,
    title: "Website Design",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },
  // Branding
  {
    id: uuidv4(),
    filter: ["branding"],
    img: branding1,
    title: "Branding",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["branding"],
    img: branding2,
    title: "Branding",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["branding"],
    img: branding3,
    title: "Branding",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },

  {
    id: uuidv4(),
    filter: ["branding"],
    img: branding4,
    title: "Branding",
    subTitle: "How an E-Commerce Website increased Sales by 50%",
    paragraph:
      "Blog Introduction: In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales.",
    description: `In this case study, we'll take a look at how an eCommerce website was able to increase their sales by 50% in just 6 months. We'll examine the changes they made to their website and explore how these changes led to such a significant increase in sales. By the end of this case study, you'll have a better understanding of how important it is to have a well-designed and user-friendly eCommerce website. 
   <br/><br/>
   <h5>The Challenge</h5>
    The client is a small eCommerce business that sells products through their website and on Amazon. When they came to us, they were struggling to keep up with the demand for their products. They had seen their sales steadily increasing over the past few years, but they weren't sure how to take their business to the next level. They wanted to increase their online presence and reach a wider audience, but they didn't know where to start. 
  <br/><br/>
 <h5>Our Solution </h5>
We started by auditing their existing website. We looked at the design, the user experience, and the overall functionality of the site. We found that there were a number of areas that could be improved. The website wasn't mobile-friendly, the checkout process was too long and complicated, and the product pages didn't provide enough information about the products. 

Based on our findings, we made a number of recommendations for how to improve the website. We suggested simplifying the checkout process, adding more visuals to the product pages, and making the site more responsive so that it would work well on all devices. The client implemented our recommendations and saw immediate results. Within 6 months, they had increased their sales by 50%. 
<br/><br/>
An eCommerce website is only as good as its design and functionality. If your website isn't user-friendly or doesn't provide enough information about your products, you're likely losing out on potential sales. In this case study, we saw how making some simple changes to an eCommerce website led to a significant increase in sales. If you're looking for ways to improve your own eCommerce website, contact us today for a free consultation.
`,
  },
];
