import fanIcon from "../img/icons/flower-icon.svg";
import handIcon from "../img/icons/hand-icon.svg";
import sunIcon from "../img/icons/sun.svg";
import darkLeftArrowIcon from "../img/icons/tooltip-arrow-left-dark.svg";
import leftArrowIcon from "../img/icons/tooltip-arrow-left.svg";
import darkRightArrowIcon from "../img/icons/tooltip-arrow-right-dark.svg";
import rightArrowIcon from "../img/icons/tooltip-arrow-right.svg";

import profileIcon from "../img/hero-banner/profile1.png";
import profileIcon3 from "../img/hero-banner/profile2.png";
import darkProfileIcon2 from "../img/hero-banner/profile3-dark.png";
import lightProfileIcon2 from "../img/hero-banner/profile3.png";

export const bannerOneData = [
  {
    toolTipText: "8 Years Experience",
    toolTipText2: "Software Engineer",
    handIcon: handIcon,
    arrow: {
      light: {
        leftArrowIcon,
        rightArrowIcon,
      },
      dark: {
        leftArrowIcon: darkLeftArrowIcon,
        rightArrowIcon: darkRightArrowIcon,
      },
    },

    name: "Habibul Hasan",
    subTitle: "Hi, I am ",
    title: "Sr. Software Engineer",
    content: `Senior Software Engineer with 7+ years of experience in building and maintaining software products using cutting-edge
    technologies. Recently architected and developed microservices that increased profit by 20%. Helping in Shipping and
    maintaining large-scale applications using microservices, docker, Kubernetes, databases and cloud providers
    (aws,azure,digital ocean).
    `,
    fanIcon: fanIcon,
    sunIcon: sunIcon,
    profileImage: profileIcon,
    cv: "Habibul Hasan.pdf",
  },
];

export const bannerTwoData = [
  {
    handIcon: handIcon,
    subTitle: "Hi, I am Habibul Hasan Hira",
    title: "Sr. Software Engineer",
    content: `Senior Software Engineer with 7+ years of experience in building and maintaining software products using cutting-edge
    technologies. Recently architected and developed microservices that increased profit by 20%. Helping in Shipping and
    maintaining large-scale applications using microservices, docker, Kubernetes, databases and cloud providers
    (aws,azure,digital ocean).
    `,
    fanIcon: fanIcon,
    sunIcon: sunIcon,
    profileImage: {
      light: lightProfileIcon2,
      dark: darkProfileIcon2,
    },
    cv: "Habibul Hasan.pdf",
  },
];

export const bannerThreeData = [
  {
    handIcon: handIcon,
    subTitle: "Hi, I am Habibul Hasan Hira",
    title: "Sr. Software Engineer",
    content: `Senior Software Engineer with 7+ years of experience in building and maintaining software products using cutting-edge
    technologies. Recently architected and developed microservices that increased profit by 20%. Helping in Shipping and
    maintaining large-scale applications using microservices, docker, Kubernetes, databases and cloud providers
    (aws,azure,digital ocean).
    `,
    fanIcon: fanIcon,
    sunIcon: sunIcon,
    profileImage: profileIcon3,
    cv: "Habibul Hasan.pdf",
  },
];
