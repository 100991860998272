import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { themeContext } from "../../context/themeContext";
import { logoContainer } from "../../data/logoContainer";
import { largeDeviceMenu } from "../../data/menu";
import "../../scss/colors/_color.scss";

const Nav = () => {
  const { theme, toggleTheme } = useContext(themeContext);

  return (
    <nav className="navbar navbar-expand-md py-3">
      <div className="container-lg">
        <Link
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          to='/'
          className="navbar-brand"
        >
          

          {/* <img
            className="logo"
            src={logoContainer[`${theme}`]}
            alt="Qawiun"
          /> */}
        </Link>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll menubar gap-4 gap-md-3 gap-lg-4">
            {largeDeviceMenu.map((menu) => (
              <li key={menu.id} className="nav-item">
                <a className="nav-link" aria-current="page" href={menu.link}>
                  {menu.text}
                </a>
                {menu?.subMenu?.length && (
                  <ul className="child-menu">
                    {menu.subMenu.map((nav) => (
                      <li key={nav.id}>
                        <NavLink
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          end
                          className={(navData) =>
                            navData.isActive ? "active" : ""
                          }
                          to={nav.link}
                        >
                          {nav.menu}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>

          <button className={`theme ${theme}`}>
            <DarkModeSwitch
              checked={theme !== "dark"}
              onChange={toggleTheme}
              size={120}
            />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
