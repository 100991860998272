import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useState } from "react";
import { BlogData } from "../../data/blog";
import BlogModal from "./blogModal";

const blogVarient = {
  left: {
    offScreen: {
      opacity: 0,
      x: -100,
    },
  },

  middle: {
    offScreen: {
      opacity: 0,
      y: 100,
    },
  },

  right: {
    offScreen: {
      opacity: 0,
      x: 100,
    },
  },

  onScreen: {
    opacity: 1,
    x: 0,
    y: 0,
  },
};

const Index = () => {
  const [isSeeAll, setSeeall] = useState(false);
  const [selectedId, setId] = useState(null);

  return (
    <section className="blog-section  pb-0" id="blogs">
      <div className="container">
        <div className="d-flex">
          <h2 className="section-heading ">
            <span className="title-signature white">My Latest Blog - Under Construction</span>
            <sub>•</sub>
          </h2>
        </div>
        <LayoutGroup type="crossfade">
          <motion.div className="row blog-items">
            {BlogData.slice(0, isSeeAll ? BlogData.length : 6).map(
              (blogData, index) => {
                const initial =
                  blogData.column === "left"
                    ? blogVarient.left.offScreen
                    : blogData.column === "middle"
                    ? blogVarient.middle.offScreen
                    : blogVarient.right.offScreen;
                return (
                  <motion.div
                    initial={initial}
                    whileInView={blogVarient.onScreen}
                    transition={{
                      delay: 0.3,
                      type: "spring",
                      stiffness: 120,
                      mass: 1,
                      bounce: 1,
                      damping: 15,
                    }}
                    viewport={{
                      amount: blogData.column === "middle" ? 0.3 : 0.5,
                    }}
                    key={index}
                    className="col-xl-4 col-lg-4 col-md-6 col-12 blogs-item"
                  >
                    <motion.a
                      onClick={() => setId(blogData.id)}
                      layoutId={`container-${blogData.id}`}
                    >
                      <div className="blog-item card">
                        <div className="blog-image">
                          <motion.img
                            layoutId={`img-${blogData.id}`}
                            src={blogData.image}
                            className="card-img-top"
                            alt="blog post"
                          />
                        </div>
                        <div className="card-body">
                          <div className="blog-date">
                            <img src={blogData.icon} alt="icon" />
                            <p className="blog-date-item">{blogData.date}</p>
                          </div>
                          <h3 className="blog-card-title">{blogData.title}</h3>
                          <div className="blog-review">
                            <div className="reviewer-image">
                              <img src={blogData.reviewImage} alt="user" />
                            </div>
                            <p className="reviewer-name">
                              <span>by</span>
                              {blogData.reviewName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </motion.a>
                  </motion.div>
                );
              }
            )}

            <AnimatePresence>
              {selectedId && <BlogModal id={selectedId} setId={setId} />}
            </AnimatePresence>
          </motion.div>
        </LayoutGroup>
        <div className="text-center mt-3">
          <button
            onClick={() => setSeeall(true)}
            className={`see-more-btn primary ${
              !isSeeAll && BlogData.length > 6 ? "d-block" : "d-none"
            }`}
            type="button"
            id="seeMoreBlog"
          >
            See more post
          </button>
        </div>
      </div>
    </section>
  );
};
export default Index;
