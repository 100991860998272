export const social = [
  {
    icon: "fa-brands fa-medium",
    href: "https://medium.com/@habibul.hasan.hira",
  },
  {
    icon: "fa-brands fa-stack-overflow",
    href: "https://stackoverflow.com/users/6395992/habibul-hasan",
  },
  {
    icon: "fab fa-linkedin-in",
    href: "https://www.linkedin.com/in/hirahasan/",
  },
  {
    icon: "fab fa-youtube",
    href: "https://www.youtube.com/@dhirahasan",
  },
];
