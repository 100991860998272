import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState } from "react";
import Blog from "../components/Blog/index";
import BottomUpButton from "../components/bottom-up-button";
import Contact from "../components/contact";
import Experience from "../components/experience";
import Expertise from "../components/expertise/expertise";
import FeatureProject from "../components/feature-project/feature-project";
import Footer from "../components/footer";
import Header from "../components/header/header";
import MobileMenu from "../components/header/mobile-menu";
import OptionalMobileMenu from "../components/optional-mobile-menu";
import Preloader from "../components/preloader";
import ProjectList from "../components/project-list/project-list";
import Testimonial from "../components/testimonial/index";
import ValidClient from "../components/valid-clinet";
import { themeContext } from "../context/themeContext";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { theme, toggleTheme } = useContext(themeContext);
  const { search } = useLocation();
  const darkTheme = search.split("=")[1];

  const [initialRender, setRender] = useState(true);

  if (theme !== "dark" && darkTheme === "dark" && initialRender) {
    toggleTheme();
    setRender(false);
  }

  return (
    <AnimatePresence key={"loader"}>
      {isLoading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
          transition={{ duration: 0.5 }}
          onAnimationComplete={() => setLoading(false)}
        >
          <Preloader />
        </motion.div>
      ) : (
        <>
          <BottomUpButton />
          <Header setShow={setShow} />
          {/* Optional Mobile Menu - ALL Pages */}
          <OptionalMobileMenu show={show} setShow={setShow} />
          {/* Mobile menu */}
          <MobileMenu />
          {/* Hero Banner Section */}
          {children}
          {/* expertise */}
          <Expertise />
          {/* experience */}
          <Experience />
          {/*feature project */}
          {/* <FeatureProject /> */}
          {/* project list */}
          <ProjectList />
          {/* Testimonial */}
          <Testimonial />
          {/* valid-clients */}
          {/* <ValidClient /> */}
          {/* blog */}
          <Blog />
          {/* contact */}
          <Contact />
          {/* footer */}
          <Footer />
        </>
      )}
    </AnimatePresence>
  );
};

export default Layout;
