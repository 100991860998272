export const timelines = [
  {
    "id": 1,
    "time": "Sept. 2023- Present",
    "position": "Senior Software Engineer",
    "company": "pocket.io",
    "location": "remote",
    "content": `Helping the compny with digital infrastructure. Designing cloud with aws,dockers,kubernaties to scale multiple applications. 
    Implementing and optimizing microservices. Leading and managing multiple teams.`
},
  {
    id: 2,
    time: "May. 2021- Aug. 2023",
    position: "LEAD Software Engineer",
    company: "raena",
    location: "UAE",
    content: `Leading a comprehensive technological overhaul, I orchestrated the integration of NestJS for robust backend development and adopted React with Redux for a streamlined frontend experience. The integration of five microservices through RabbitMQ and Redis played a pivotal role in refining our customer support operations. Concurrently, I designed and implemented an effective data-migration pipeline in Python, resulting in an impressive 90% reduction in manual data entry efforts. Expanding our impact, I successfully implemented a logistics cost optimization system, seamlessly aligning with three major logistics and transport partners and leading to a significant 20% reduction in last-mile delivery costs. Embracing a holistic approach, close collaboration with the warehouse, logistics, and customer support teams proved crucial in improving our return and refund processes`,
  },
  {
    id: 3,
    time: "Feb 2021- Feb 2022",
    position: "Sr. Software Engineer",
    company: "Priyo Sys",
    location: "Dhaka Bangladesh",
    content: `As a seasoned technical software engineer, I led teams of 10, overseeing the codebase and daily operations. Using tools like Jira and Notion, I maintained seamless workflows through regular meetings and collaboration. I played a crucial role in designing and developing internal messaging platforms, utilizing a tech stack including NodeJS, SocketIO, TypeScript, MongoDB, and React—resulting in a substantial upgrade to our communication infrastructure.
    Additionally, I initiated the development of a universal data submission frontend framework, transforming our organization's data collection processes. This effort streamlined operations, eliminating the need for new forms and promoting a more efficient workflow. My hands-on experience underscores a consistent commitment to innovation and efficiency in software engineering.`,
  },
  {
    id: 4,
    time: "2019- Jan. 2021",
    position: "Software Engineer",
    company: "Shohoz",
    location: "Dhaka Bangladesh",
    content: ` 
    Designing and implementing an internal notification system with Dotnet Core SignalR during system architecture research achieved a notable 50% reduction in communication delays between teams. This effort enhanced cross-functional collaboration and shortened project timelines.
    
    Creating a dispatcher panel with Dotnet Core, TypeScript, Angular, HTML, CSS, and SocketIO significantly improved operational efficiency, resulting in a 22% reduction in operational time. This action contributed to a more streamlined workflow, showcasing my hands-on approach to development and adept problem-solving in diverse technical settings.`,
  },
  {
    id: 5,
    time: "2016- Jan. 2019",
    position: "Software Engineer",
    company: "imshealth",
    location: "Washington, United States",
    content: `Programmed a highly scalable business analytics customer subscription portal for international pharmaceuticals,
    leveraging ASP .Net MVC framework; optimized data processing and analysis, resulting in a 40% reduction in report
    generation time.
    ● Developed advanced analytics tools utilizing Python,NodeJS and SQL, enabling real-time data visualization and
    predictive modeling for pharmaceutical research; improved decision-making accuracy by 25% and minimized
    time-to-insight by 50%.`,
  },
  {
    id: 6,
    time: "2015",
    position: "Software Engineer",
    company: "Self-Funded Startup",
    location: "Dhaka,Bangladesh",
    content: `Designed, developed, and implemented a multi-channel e-commerce platform for online retail stores. Served clients with a seamless checkout experience.`,
  },
];
