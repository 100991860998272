import { motion } from "framer-motion";
import data from "../data/video.json";

const Varients = {
  offScreen: {
    y: "50%",
    x: "-50%",
    opacity: 0,
  },
  onScreen: {
    y: "-50%",
    x: "-50%",
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 120,
      bounce: 0.8,
      damping: 25,
      mass: 0.9,
    },
  },
  exit: {
    y: "50%",
    x: "-50%",
    opacity: 0,
    transition: { duration: 0.3 },
  },
};

const VideoModal = ({ setOpen }) => {
  return (
    <motion.div
      className="video-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="container">
        <motion.div
          variants={Varients}
          initial="offScreen"
          animate="onScreen"
          exit="exit"
          className="video-modal p-3"
        >
          <button onClick={() => setOpen(false)} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="ratio ratio-16x9 bg-dark">
            {!data.custom ? (
              <iframe
                src="https://www.youtube.com/embed/P2E17mKStXc"
                title={data.title}
               allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            ) : (
              <video className="text-center" controls autoplay={true}>
                <source src={data.url} type="video/mp4" />
                {data.message}
              </video>
            )}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default VideoModal;