export const projectData = [
  {
    id: 1,
    project: "15",
    description: "Completed Projects",
  },
  {
    id: 2,
    project: "20",
    description: "Happy Clients",
  },
  {
    id: 3,
    project: "5",
    description: "Academic awards",
  },
  {
    id: 4,
    project: "100",
    description: "Professionals Trained",
  },
];
